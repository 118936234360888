<template>
    <el-row class="pb-3">
        <ModalUpdatePassword
            v-if="modalUpdatePasswordVisible"
            :modalUpdatePassword="modalUpdatePassword"
            @close="modalUpdatePassword = false"
            @closed="modalUpdatePasswordVisible = false"
        />

        <el-col :span="16" :offset="4">
            <div class="card">
                <el-form ref="form" :model="formData" :rules="formRules" label-position="top">
                    <header class="border-b border-solid border-gray-300 pb-4 mb-5">
                        <h1>{{ $t('settings.general_information') }}</h1>
                    </header>
                    <div
                        v-loading="$wait.is('loading')"
                        class="grid md:grid-cols-2 sm:grid-cols-1 gap-4 row-gap-0 px-8"
                        element-loading-spinner="el-custom-spinner"
                    >
                        <el-form-item :label="$t('settings.company_name')" prop="company_name">
                            <el-input v-model="formData.company_name" tabindex="1" />
                        </el-form-item>
                        <el-form-item :label="$t('settings.org_per_nummer')" prop="org_per_nummer">
                            <el-input v-model="formData.org_per_nummer" tabindex="2" />
                        </el-form-item>
                        <el-form-item :label="$t('settings.website')" prop="website">
                            <el-input v-model="formData.website" tabindex="3" />
                        </el-form-item>
                        <el-form-item :label="$t('settings.email_login_as_well')" prop="email">
                            <el-input v-model="formData.email" tabindex="4" />
                        </el-form-item>
                    </div>

                    <header class="border-b border-solid border-gray-300 pb-4 mb-5 mt-12">
                        <h1>{{ $t('settings.address') }}</h1>
                    </header>
                    <div
                        v-loading="$wait.is('loading')"
                        class="grid md:grid-cols-2 sm:grid-cols-1 gap-4 row-gap-0 px-8"
                        element-loading-spinner="el-custom-spinner"
                    >
                        <el-form-item :label="$t('settings.street')" prop="street">
                            <el-input v-model="formData.street" tabindex="5" />
                        </el-form-item>
                        <el-form-item :label="$t('settings.post_code')" prop="post_code">
                            <el-input v-model="formData.post_code" tabindex="6" />
                        </el-form-item>
                        <el-form-item :label="$t('settings.city')" prop="city">
                            <el-input v-model="formData.city" tabindex="7" />
                        </el-form-item>
                        <el-form-item :label="$t('settings.country')" prop="country">
                            <el-input v-model="formData.country" :disabled="true" />
                        </el-form-item>
                        <el-form-item :label="$t('settings.telephone')" prop="telephone">
                            <el-input v-model="formData.telephone" tabindex="8" />
                        </el-form-item>
                    </div>

                    <header class="border-b border-solid border-gray-300 pb-4 mb-5 mt-12">
                        <h1>{{ $t('settings.system_options') }}</h1>
                    </header>
                    <div v-loading="$wait.is('loading')" class="grid grid-cols-1 gap-4 row-gap-0 px-8" element-loading-spinner="el-custom-spinner">
                        <el-form-item :label="$t('settings.system_language')">
                            <el-select v-model="formData.language" tabindex="9">
                                <el-option value="se" label="Svenska" />
                                <el-option value="en" label="English" />
                                <el-option value="pl" label="Polski" />
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('settings.calendar_confirmation')">
                            <el-switch
                                v-model="formData.calendar_confirmation"
                                active-color="#cacaca"
                                inactive-color="#13ce66"
                                :active-value="0"
                                :inactive-value="1"
                                :active-text="$t('common.no')"
                                :inactive-text="$t('common.yes')"
                            />
                        </el-form-item>
                        <el-form-item :label="$t('settings.email_to_employees_about_changes_in_calendar')">
                            <el-switch
                                v-model="formData.calendar_changes_email"
                                active-color="#cacaca"
                                inactive-color="#13ce66"
                                :active-value="0"
                                :inactive-value="1"
                                :active-text="$t('common.no')"
                                :inactive-text="$t('common.yes')"
                            />
                        </el-form-item>
                        <el-form-item :label="$t('settings.auto_generated_client_custom_id')">
                            <el-switch
                                v-model="formData.auto_client_custom_id"
                                active-color="#cacaca"
                                inactive-color="#13ce66"
                                :active-value="0"
                                :inactive-value="1"
                                :active-text="$t('common.no')"
                                :inactive-text="$t('common.yes')"
                            />
                        </el-form-item>
                    </div>

                    <div class="flex justify-end mt-8">
                        <el-button type="warning" :disabled="!userCan('update settings')" @click="updatePasswordModal">
                            {{ $t('settings.change_password') }}
                        </el-button>
                        <el-button :loading="$wait.is('updating')" type="primary" :disabled="!userCan('update settings')" @click="update">
                            {{ $t('common.update') }}
                        </el-button>
                    </div>
                </el-form>
            </div>
        </el-col>
    </el-row>
</template>
<script>
import Cookies from 'js-cookie';

export default {
    components: {
        ModalUpdatePassword: () => import(/* webpackChunkName: "SettingsGeneral/ModalUpdatePassword" */ './modals/ModalUpdatePassword'),
    },

    data() {
        return {
            modalUpdatePasswordVisible: false,
            modalUpdatePassword:        false,
            primalLanguage:             null,
            formData:                   {
                company_name:   null,
                org_per_nummer: null,
                website:        null,
                email:          null,
                street:         null,
                post_code:      null,
                city:           null,
                country:        null,
                telephone:      null,
                language:       null,
            },
            formRules: {
                email: [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { type: 'email', message: this.$t('common.wrong_email_format'), trigger: 'blur' },
                ],
                company_name:   [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                org_per_nummer: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                street:         [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:           [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('settings.settings_general'));
        this.$store.commit('setActiveMenuItem', '99-1');

        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/settings/general`);
            this.formData.company_name = data.details.company_name;
            this.formData.org_per_nummer = data.details.org_per_nummer;
            this.formData.website = data.details.website;
            this.formData.email = data.email;
            this.formData.street = data.details.street;
            this.formData.post_code = data.details.post_code;
            this.formData.city = data.details.city;
            this.formData.country = data.details.country;
            this.formData.telephone = data.details.telephone;
            this.formData.language = data.details.language;
            this.formData.calendar_confirmation = data.calendar_event_confirmation;
            this.formData.calendar_changes_email = data.calendar_changes_email;
            this.formData.auto_client_custom_id = data.auto_client_custom_id;
            this.primalLanguage = data.details.language;
            this.$wait.end('loading');
        },

        update() {
            this.$refs.form.validate(async valid => {
                if (!valid) return false;

                this.$wait.start('updating');
                try {
                    await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/settings/general`, {
                        company_name:                this.formData.company_name,
                        org_per_nummer:              this.formData.org_per_nummer,
                        website:                     this.formData.website,
                        email:                       this.formData.email,
                        street:                      this.formData.street,
                        post_code:                   this.formData.post_code,
                        city:                        this.formData.city,
                        telephone:                   this.formData.telephone,
                        language:                    this.formData.language,
                        calendar_event_confirmation: this.formData.calendar_confirmation,
                        calendar_changes_email:      this.formData.calendar_changes_email,
                        auto_client_custom_id:       this.formData.auto_client_custom_id,
                    });
                    Cookies.set('language', this.formData.language, { expires: 365 });
                    this.$wait.end('updating');
                    this.$notify.success({
                        title: 'Success',
                    });

                    this.$i18n.locale = this.formData.language;
                    this.$router.go();
                } catch (error) {
                    this.$wait.end('updating');
                }
            });
        },

        async updatePasswordModal() {
            this.modalUpdatePasswordVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalUpdatePassword = true;
        },
    },
};
</script>
